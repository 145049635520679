import axios from 'axios';
import store from '@/store';
import { _rotasAPI } from '@/services/_caminhosApi';
import { tokenExpirado } from '@/utils/tokenExpirado';
//

export function editarBannerHome(data) {


  return new Promise((resolve) => {
    const token = localStorage.getItem('token');
    store.commit('SET_ADICIONAR_LOADING');
    axios
      .put(_rotasAPI().editarBanner, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        
        resolve(resp);
      })
      .catch((erro) => {
       console.log(erro)
      })
      .finally(() => store.commit('SET_REMOVER_LOADING'));
  });
}

export function cadastrarBannerHome(data) {
  return new Promise((resolve) => {
    const token = localStorage.getItem('token');
    store.commit('SET_ADICIONAR_LOADING');
    axios
      .post(_rotasAPI().cadastrarBannerHome, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        resolve(resp);
      })
      .catch((erro) => {
        tokenExpirado(erro, true);
      })
      .finally(() => store.commit('SET_REMOVER_LOADING'));
  });
}

export function listarCnpj() {
  return new Promise((resolve) => {
    const token = localStorage.getItem('token');
    store.commit('SET_ADICIONAR_LOADING');
    axios
      .get(`${_rotasAPI().listarCnpj}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        resolve(resp);
      })
      .catch((erro) => {
        tokenExpirado(erro, true);
      })
      .finally(() => store.commit('SET_REMOVER_LOADING'));
  });
}

export function tipoDeFiltro() {
  return new Promise((resolve) => {
    const token = localStorage.getItem('token');
    store.commit('SET_ADICIONAR_LOADING');
    axios
      .get(`${_rotasAPI().tipoDeFiltro}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        resolve(resp);
      })
      .catch((erro) => {
        tokenExpirado(erro, true);
      })
      .finally(() => store.commit('SET_REMOVER_LOADING'));
  });
}

export function listarBannersHome(data) {
  return new Promise((resolve) => {
    const token = localStorage.getItem('token');
    store.commit('SET_ADICIONAR_LOADING');
    axios
      .post(`${_rotasAPI().listarBannersHome}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        resolve(resp);
      })
      .catch((erro) => {
        tokenExpirado(erro, true);
      })
      .finally(() => store.commit('SET_REMOVER_LOADING'));
  });
}

export function obterDetalhesBanner(id) {
  return new Promise((resolve) => {
    const token = localStorage.getItem('token');
    store.commit('SET_ADICIONAR_LOADING');
    axios
      .get(`${_rotasAPI().obterDetalhesBanner}${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        resolve(resp);
      })
      .catch((erro) => {
        tokenExpirado(erro, true);
      })
      .finally(() => store.commit('SET_REMOVER_LOADING'));
  });
}

export function atualizarBannerHome(data) {
  return new Promise((resolve) => {
    const token = localStorage.getItem('token');
    store.commit('SET_ADICIONAR_LOADING');
    axios
      .put(_rotasAPI().atualizarBannerHome, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        resolve(resp);
      })
      .catch((erro) => {
        tokenExpirado(erro, true);
      })
      .finally(() => store.commit('SET_REMOVER_LOADING'));
  });
}


// Rotas antigas
export function listarPromocoes(filtro, pag = 1) {
  return new Promise((resolve) => {
    const token = localStorage.getItem('token');
    store.commit('SET_ADICIONAR_LOADING');
    axios
      .post(`${_rotasAPI().listarPromocoes}?pagina=${pag}&quantidadePorPagina=${15}`, filtro, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        resolve(resp);
      })
      .catch((erro) => {
        store.commit('promocoes/SET_CARREGADO', true);
        tokenExpirado(erro, true);
      })
      .finally(() => store.commit('SET_REMOVER_LOADING'));
  });
}
export function buscarPromocoes(id) {
  return new Promise((resolve) => {
    const token = localStorage.getItem('token');
    store.commit('SET_ADICIONAR_LOADING');
    axios
      .get(`${_rotasAPI().buscarPromocoes}${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        resolve(resp);
      })
      .catch((erro) => {
        tokenExpirado(erro, true);
      })
      .finally(() => store.commit('SET_REMOVER_LOADING'));
  });
}
export function buscarTiposMecanicas() {
  return new Promise((resolve) => {
    const token = localStorage.getItem('token');
    store.commit('SET_ADICIONAR_LOADING');
    axios
      .get(`${_rotasAPI().buscarMecanicas}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        resolve(resp);
      })
      .catch((erro) => {
        tokenExpirado(erro, true);
      })
      .finally(() => store.commit('SET_REMOVER_LOADING'));
  });
}

export function promocaoPromoshot() {
  return new Promise((resolve) => {
    const token = localStorage.getItem('token');
    store.commit('SET_ADICIONAR_LOADING');
    axios
      .get(_rotasAPI().promocaoPromoshot, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        resolve(resp);
      })
      .catch((erro) => {
        tokenExpirado(erro, true);
      })
      .finally(() => store.commit('SET_REMOVER_LOADING'));
  });
}
export function editarPromocoes(data) {
  return new Promise((resolve) => {
    const token = localStorage.getItem('token');
    store.commit('SET_ADICIONAR_LOADING');
    axios
      .post(_rotasAPI().editarPromocoes, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        resolve(resp);
      })
      .catch((erro) => {
        tokenExpirado(erro, true);
        store.commit("SET_MODAL", {
          ativado: true,
          mensagem: "Algo deu errado",
          tipo: "erro",
        });
      })
      .finally(() => store.commit('SET_REMOVER_LOADING'));
  });
}