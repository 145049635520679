<template>
	<div>
		<div class="banner">
			<div class="conteudo-interno">
				<div class="nova-comunicacao-container">
					<h1>Cadastro de Banner Home</h1>
					<div class="container-btns" v-if="perfil != 5 && perfil != null">
						<button class="btn btn-laranja" @click="adicionarBanner">
							Adicionar
						</button>
					</div>
					<form @submit.prevent class="formulario-container">
						<div class="formulario-linha flex">
							<div class="formulario-item medio">
								<label class="formulario-item-label">Descrição</label>
								<input type="text" v-model="form.descricao" />
							</div>
							<div class="formulario-item medio">
								<label class="formulario-item-label">Data inicio</label>
								<datepicker :language="ptBR" v-model="form.dataInicio" />
							</div>
							<div class="formulario-item medio">
								<label class="formulario-item-label">Data final</label>
								<datepicker :language="ptBR" v-model="form.dataFim" />
							</div>
							<div class="formulario-item medio">
								<label class="formulario-item-label">Ativo</label>
								<multiselect v-model="form.ativo" label="nome" track-by="nome" :options="ativo"
									:searchable="false" :multiple="false" placeholder="" select-label="Selecionar"
									deselect-label="Remover" selected-label="Selecionado" />
							</div>
							<div class="formulario-item medio">
								<label class="formulario-item-label">Segmentação</label>
								<multiselect v-model="form.idAgrangencia" label="descricao" track-by="descricao"
									:options="segmentacao" :searchable="false" :multiple="false" placeholder=""
									select-label="Selecionar" deselect-label="" selected-label="Selecionado"
									:allow-empty="false" @close="fecharSelect" @input="
										(form.idsSegmentacao = []), (assistenciaSelecionados = [])
									" />
							</div>

							<div v-if="form.idAgrangencia && form.idAgrangencia.id === 5" class="formulario-item medio">
								<label class="formulario-item-label">Grupo</label>
								<multiselect ref="grupo" v-model="form.idsSegmentacao" label="descricao"
									track-by="descricao" :options="grupo" :searchable="false" :multiple="true"
									:allow-empty="true" placeholder="" select-label="Selecionar"
									deselect-label="Remover" selected-label="Selecionado" @close="fecharSelect()" />
							</div>

							<div v-if="form.idAgrangencia && form.idAgrangencia.id === 4" class="formulario-item medio">
								<label class="formulario-item-label">Região</label>
								<multiselect ref="regiao" v-model="form.idsSegmentacao" label="descricao"
									track-by="descricao" :options="regiao" :searchable="false" :multiple="true"
									:allow-empty="true" placeholder="" select-label="Selecionar"
									deselect-label="Remover" selected-label="Selecionado" @close="fecharSelect" />
							</div>

							<div v-if="form.idAgrangencia && form.idAgrangencia.id === 6" class="formulario-item medio">
								<label class="formulario-item-label">Escolha a autorizada</label>
								<multiselect ref="assistencia" v-model="franquiaAutorizada" label="descricao"
									track-by="descricao" :options="opFranqAutorizada" :searchable="true"
									:multiple="false" :allow-empty="false" placeholder="" select-label="Selecionar"
									deselect-label="Remover" selected-label="Selecionado" @input="
										(assistenciaSelecionados = []), (form.idsSegmentacao = [])
									" />
							</div>

							<div v-if="franquiaAutorizada.id == 1 && form.idAgrangencia.id === 6"
								class="formulario-item medio">
								<label class="formulario-item-label">Assistência</label>
								<multiselect ref="assistencia" label="descricao" track-by="descricao"
									v-model="form.idsSegmentacao" :options="assistencia" :searchable="true"
									:allow-empty="false" placeholder="" select-label="Selecionar"
									deselect-label="Remover" selected-label="Selecionado"
									@input="assistenciaSelecionado()" />
							</div>

							<div v-if="franquiaAutorizada.id == 2 && form.idAgrangencia.id === 6"
								class="formulario-item medio">
								<label class="formulario-item-label">Franquia</label>
								<multiselect ref="assistencia" label="descricao" track-by="descricao"
									v-model="form.idsSegmentacao" :options="franquia" :searchable="true"
									:multiple="false" :allow-empty="false" placeholder="" select-label="Selecionar"
									deselect-label="Remover" selected-label="Selecionado"
									@input="assistenciaSelecionado()" />
							</div>
						</div>

						<div class="box-excluir-todos">
							<p class="btn-excluir" @click="
								(assistenciaSelecionados = []), (form.idsSegmentacao = [])
							" v-if="assistenciaSelecionados.length > 0">
								Limpar assistências
							</p>
						</div>
						<div id="box-filtro-assistencia" v-if="assistenciaSelecionados.length > 0">
							<div v-if="assistenciaSelecionados.length > 0" class="tabela-informacoes">
								<div class="
                    box-colunas-assistencia
                    tabela-linha tabela-linha-informacoes
                    quebra-linha
                  ">
									<div v-for="(assistencia, index) in assistenciaSelecionados" :key="index"
										class="coluna-nome-assistencia">
										<p>{{ assistencia.descricao }}</p>
										<button @click="
											assistenciaSelecionados.splice(
												assistenciaSelecionados.indexOf(assistencia),
												1
											)
										" class="btn-excluir-assistencia">
											X
										</button>
									</div>
								</div>
							</div>
						</div>
						<div class="container-btns">
							<button class="btn btn-cancelar" @click="limparFiltro">
								Limpar
							</button>
							<button class="btn btn-laranja" @click="filtrar">Filtrar</button>
						</div>
					</form>
					<div v-if="semDados" class="tabela-sem-dados" key="Vazio">
						<p>Sem dados</p>
					</div>
					<div v-else class="tabela-informacoes" key="Lista">
						<div class="tabela-linha tabela-linha-titulo">
							<div class="tabela-linha-nome">
								<p>Título Banner</p>
							</div>
							<div class="tabela-linha-nome">
								<p>Link</p>
							</div>
							<div class="tabela-linha-nome">
								<p>Descrição</p>
							</div>
							<div class="tabela-linha-data-inicio">
								<p>Data Inicio</p>
							</div>
							<div class="tabela-linha-data-final">
								<p>Data Fim</p>
							</div>
							<div class="tabela-linha-imagem">
								<p>Banner Desktop</p>
							</div>
							<div class="tabela-linha-imagem">
								<p>Banner Mobile</p>
							</div>
							<div class="tabela-linha-btns"></div>
						</div>
						<div class="tabela-linha tabela-linha-informacoes" v-for="(item, index) in dadosTabela"
							:key="index">
							<div class="tabela-linha-nome">
								<p>{{ item.descricao }}</p>
							</div>
							<div class="tabela-linha-nome">
								<p>{{ item.link }}</p>
							</div>
							<div class="tabela-linha-nome">
								<p>{{ item.descricao }}</p>
							</div>
							<div class="tabela-linha-data-inicio">
								<p>{{ formatarDataInicial(item.dataInicio) }}</p>
							</div>
							<div class="tabela-linha-data-final">
								<p>{{ formatarDataInicial(item.dataFim) }}</p>
							</div>
							<div class="tabela-linha-imagem">
								<a :style="
									`background: url('${item.imagemDesktop}') center center / contain no-repeat;`
								"></a>
							</div>
							<div class="tabela-linha-imagem">
								<a :style="
									`background: url('${item.imagemMobile}') center center / contain no-repeat;`
								"></a>
							</div>
							<div class="tabela-linha-btns">
								<div class="tabela-linha-btns-editar" @click="editarBanner(item.id)">
									<img src="@/assets/images/icones/editar.svg" alt="Editar" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { tipoDeFiltro, listarBannersHome } from "@/services/banner-home";
import {
	listarRegioes,
	listarAssistencias,
	listarGrupo
} from "@/services/autorizadas";
import Datepicker from "vuejs-datepicker";
import { ptBR } from "vuejs-datepicker/dist/locale";
import Multiselect from "vue-multiselect";
import moment from "moment";
export default {
	name: "CadastroBannerHomeListar",

	components: {
		Multiselect,
		Datepicker
	},

	data() {
		return {
			ptBR: ptBR,
			semDados: true,
			dadosTabela: [],
			segmentacao: [],
			franquiaAutorizada: [],
			form: {
				descricao: "",
				dataInicio: "",
				dataFim: "",
				ativo: true,
				idAbrangencia: null,
				idsSegmentacao: []
			},
			opFranqAutorizada: [
				{
					id: 1,
					descricao: "Assistencia"
				},
				{
					id: 2,
					descricao: "Franquia"
				}
			],
			ativo: [
				{ id: true, nome: "SIM" },
				{ id: false, nome: "NÃO" }
			],
			regiao: [],
			grupo: [],
			assistencia: [],
			franquia: [],
			assistenciaSelecionados: [],
			selectTodosAssistencia: [],
			selectTodosFranquia: [],
			perfil: null
		};
	},

	created() {
		this.selecionarFiltro();
		this.listar();
		this.buscarAssistenciasSegmentacao();
		this.perfil = localStorage.getItem('perfil');
	},

	methods: {
		buscarAssistenciasSegmentacao() {
			listarAssistencias(1).then(resp => {
				this.selectTodosAssistencia = resp.data.map(item => item.id);
				this.assistencia = resp.data;
				this.assistencia.unshift({ id: "assistencia", descricao: "Todos" });
			});
			listarAssistencias(2).then(resp => {
				this.selectTodosFranquia = resp.data.map(item => item.id);
				this.franquia = resp.data;
				this.franquia.unshift({ id: "franquia", descricao: "Todos" });
			});
		},
		listarGrupos() {
			listarGrupo().then(resp => {
				this.grupo = resp.data;
			});
		},
		fecharSelect() {
			if (!this.form.cliente || this.form.cliente.length < 1) {
				this.form.cliente = null;
			}
		},
		filtrar() {
			const data = {
				descricao: this.form.descricao ? this.form.descricao : "",
				dataInicio: this.form.dataInicio
					? moment(this.form.dataInicio).format("YYYY-MM-DD")
					: "",
				dataFim: this.form.dataFim
					? moment(this.form.dataFim).format("YYYY-MM-DD")
					: "",
				ativo: this.form.ativo ? this.form.ativo.id : 0,
				idsSegmentacao: [],
				idAbrangencia: this.form.idAbrangencia
					? this.form.idAbrangencia.id
					: "0",
				pagina: 0,
				qtdPorPagina: 0
			};

			if (this.form.idsSegmentacao.id == "assistencia") {
				data.idsSegmentacao = this.selectTodosAssistencia;
			}

			if (this.form.idsSegmentacao.id == "franquia") {
				data.idsSegmentacao = this.selectTodosFranquia;
			}

			listarBannersHome(data).then(resp => {
				if (resp.data.lista.length > 0) {
					this.dadosTabela = resp.data.lista;
					this.semDados = false;
				}
				this.dadosTabela = resp.data.lista;
			});
		},
		limparFiltro() {
			(this.form = {
				descricao: "",
				dataInicio: "",
				dataFim: "",
				ativo: true,
				idAbrangencia: null,
				idsSegmentacao: []
			}),
				(this.assistenciaSelecionados = []);
			this.franquiaAutorizada = [];
			this.listar();
		},

		formatarDataInicial(data) {
			return moment(data).format("DD/MM/YYYY");
		},

		selecionarFiltro() {
			tipoDeFiltro().then(resp => {
				this.segmentacao = resp.data.filter(
					item => item.id !== 2 && item.id !== 3 && item.id !== 6
				);
				this.segmentacao.push({ id: 6, descricao: "Por Autorizada" });
			});
			this.buscarAssistenciasSegmentacao();
		},

		adicionarBanner() {
			this.$router.push("/cadastro-banner-home/adicionar");
		},
		listar() {
			const data = {
				descricao: "",
				dataInicio: "",
				dataFim: "",
				idAbrangencia: 0,
				idsSegmentacao: [],
				ativo: true,
				pagina: 0,
				qtdPorPagina: 0
			};
			listarBannersHome(data).then(resp => {
				if (resp.data.lista.length > 0) {
					this.dadosTabela = resp.data.lista;
					this.semDados = false;
				}
			});
			this.listarObterRanking();
			this.buscarAssistenciasSegmentacao();
			this.listarGrupos();
		},
		editarBanner(id) {
			this.$router.push(`/cadastro-banner-home/editar/${id}`);
		},
		assistenciaSelecionado() {
			if (
				this.form.idsSegmentacao.id == "assistencia" ||
				this.form.idsSegmentacao.id == "franquia"
			) {
				this.assistenciaSelecionados = [];
				return;
			}
			if (this.form.idsSegmentacao != null) {
				this.assistenciaSelecionados.push(this.form.idsSegmentacao);
				this.form.idsSegmentacao = this.assistenciaSelecionados;
			}
		},
		listarObterRanking() {
			listarRegioes().then(resp => {
				this.regiao = resp.data;
			});
		}
	}
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"/>

<style lang="scss">
#box-filtro-assistencia {
	width: 100%;

	.tabela-informacoes {
		height: auto;
		max-height: 100px;
	}

	.tabela-linha.tabela-linha-informacoes .coluna-nome-assistencia {
		width: 250px;
		padding: 0px 10px;
		margin-bottom: 4px;
	}

	.quebra-linha {
		flex-wrap: wrap;
	}

	.box-colunas-assistencia {
		width: 100%;
		display: flex;
	}
}

.box-excluir-todos {
	width: 100%;
	margin-left: 15px;
	display: flex;
}

.btn-excluir-assistencia,
.btn-excluir {
	margin: 10px 0;
	font-weight: 800;
	width: auto;
	color: #fff;
	padding: 10px;
	border-radius: 5px;
	background: #f28809;
	text-align: center;
	cursor: pointer;
	display: block;
}

.btn-excluir-assistencia {
	padding: 5px;
	border: none;
	width: 30px;
}

.banner {
	position: relative;
	display: flex;
	overflow: hidden;
	width: 100%;
	height: 100%;

	.tabela-linha-btns {
		min-width: 50px;
		max-width: 50px;
	}

	.flex {
		display: flex;
		justify-content: flex-start;
	}

	.medio {
		width: 33.33% !important;
	}
}
</style>
